import "./css/tachyons.min.css";
import { Amplify } from "aws-amplify"
import React, { useEffect, useState } from "react";
import { Authenticator, useAuthenticator, useTheme, View, Image, Button, Link, translations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { SelectField, TextField, CheckboxField } from '@aws-amplify/ui-react';
import { Hub, ConsoleLogger as Logger } from 'aws-amplify/utils';
import axios from "axios";
import AppWUS from "./AppWUS";
import { useSessionStorage } from './useSessionStorage';
import { useInterval } from "./useInterval";
import { activityTracker } from "./sessionUtilities";
import { INTERVAL_TIME } from "./sessionUtilities";
import { storeWebSession } from "./utility/DbService";
import { unload_handler } from "./sessionUtilities";
import { useNavigate } from "react-router-dom";
import { service_url, email_service, mailto_list, request_config, mfa_guide_url } from "./config";
import { userLogoutDB } from "./utility/DbService";
import Pending from "./Pending"
import Swal from "sweetalert2";

import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { fetchMFAPreference, fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';
import { I18n } from 'aws-amplify/utils';
// import {  } from '@aws-amplify/ui-react';
import {
  signUp,
  signIn,
  confirmSignIn,
  confirmSignUp,
  updateMFAPreference,
  autoSignIn,
  setUpTOTP,
  verifyTOTPSetup
} from 'aws-amplify/auth';
import { CompareSharp } from "@mui/icons-material";

I18n.putVocabularies(translations);
I18n.setLanguage('en');
I18n.putVocabularies({
  en: {
    'Setup TOTP':
      'Scan this QR code to enable 2 factor authentication for your account' // Customize the title here

  },
});



Amplify.configure(awsExports);

cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

const security_app_url = "https://www.microsoft.com/en-us/security/mobile-authenticator-app"
const logger = new Logger('WUS_log', 'DEBUG');


const App = () => {
  sessionStorage.setItem("reloading", true);
  let navigate = useNavigate();
  var coming_from_signup = false;
  var confirmed_email = false; // 
  var auth_listened = false;
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  // const { toSignIn } = useAuthenticator();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const { route } = useAuthenticator(context => [context.route]);
  const [approvalStatus, setApprovalStatus] = useState('');
  const [auth_status_var, setAuthStatus] = useSessionStorage('auth_status', '');

  const addUser = (user_id, user_name) => {
    // console.log("ADD to DB userid: ", user_id)
    // console.log("ADD to DB username: ", user_name)
    let dataString = {
      "UserID": user_id,
      "UserName": user_name,
      "Email": sessionStorage.getItem('user_email'),
      "Role": sessionStorage.getItem('role'),
      "first_name": sessionStorage.getItem('fname'),
      "last_name": sessionStorage.getItem('lname'),
      "institution": sessionStorage.getItem('site_id'),
      "signup_login": coming_from_signup,
      "is_provider": sessionStorage.getItem('role') === '3',
      "LoginStatus": false,
    };

    axios.post(service_url + "/add_user", dataString, request_config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "User Registered",
          allowOutsideClick: false,
          html: "User has been successfully registered. <br/> Return to the login page to login into your account."
        }).then((swal_response) => {
          
          axios.post(email_service + "/emailAdmin", { "username": dataString['UserName'], 
          "user_email": dataString['Email'],"site":dataString['institution'] }, request_config)
            .then((response) => {
              setApprovalStatus("Pending")
            }, (error) => {
              setApprovalStatus('Error')
              Swal.fire({
                icon: "error",
                title: "Connection Error (Notify Admin)",
                allowOutsideClick: false,
                html: "Unable to notify the administrator. Please <a href=" + { mailto_list } + " target='_blank'>email support</a> \
                            and send a screenshot of this error message<br><br>\
                            <div class='outline>"+ error + "</div>"
              }).then((email_response) => {
                setTimeout(() => {
                  sessionStorage.clear()
                  window.location.reload();
                }, 500);
              }, (email_error)=>{
                console.log("Email ADMIN ERROR:", email_error);
              })
              
            });

        }, (swal_error) => {
          console.log("SERVICE CONNECTION ERROR: ",swal_error)
        })
      }, (error) => {
        Swal.fire({
          icon: "error",
          title: "Connection Error",
          allowOutsideClick: false,
          html: "Unable to register the user. Please <a href=" + { mailto_list } + " target='_blank'>email support</a> \
                and send a screenshot of this error message<br><br>\
                <div class='outline black'>"+ error + "</div>"
        }).then((response) => { })
      })
  }
  const getUser = (user) => {
    fetchUserAttributes().then((attr) => {
      let email = attr["email"]
      let user_id = user['userId']
      let username = user['username']
      // console.log(user_id, username, email)
      let dataString = {
        "UserID": user_id,
        "UserName": username,
        "signup_login": false,
        "LoginStatus": true
      }
      sessionStorage.setItem("user_id", user_id);
      sessionStorage.setItem("user_name", username);
      sessionStorage.setItem("user_email", email);


      axios.post(service_url + "/user", dataString, request_config)
        .then((response) => {
          // console.log("Response:", response.data)
          // console.log("SETTING VARIABLES:", username, user_id);
          setApprovalStatus(response.data["status"])
          sessionStorage.setItem("site_id", response.data['site_id']);
          sessionStorage.setItem("role", response.data['role']);
        }, (error) => {
          setApprovalStatus('Error')
          console.log(error)
          Swal.fire({
            icon: "error",
            title: "Connection Error",
            allowOutsideClick: false,
            html: "Unable to get user details. Please <a href=" + { mailto_list } + " target='_blank'>email support</a> \
                                and send a screenshot of this error message<br><br>\
                                <b>"+ error + "</b>"
          }).then((response) => {

          })
          console.log(error);
        });
    }
      , (error) => {
        setApprovalStatus('Error')
        console.log(error)
        Swal.fire({
          icon: "error",
          title: "Connection Error",
          allowOutsideClick: false,
          html: "Unable to get user details. Please <a href=" + { mailto_list } + " target='_blank'>email support</a> \
                                and send a screenshot of this error message<br><br>\
                                <b>"+ error + "</b>"
        }).then((response) => {

        })
      })
  }

  useEffect(() => {

    Hub.listen('auth', (data) => {
      const { payload } = data;
      // console.log(
      //   'A new auth event has happened: ',
      //   (data.payload.data ? data.payload.data.username : ' ') + data.payload.event
      // );
      auth_listened = true
    });

    if (!auth_listened && sessionStorage.getItem('user_name') && sessionStorage.getItem('user_id')) {
      // Without user id it signup process has not been completed 
      console.log("Getting Approval Status on Refresh")
      // Get Approval Status
      var dataString = {
        "UserID": sessionStorage.getItem('user_id'),
        "UserName": sessionStorage.getItem('user_name'),
        "signup_login": false,
        "LoginStatus": true,
      };
      axios.post(service_url + "/user", dataString, request_config)
        .then((response) => {
          console.log("Response:", response.data["status"])

          setApprovalStatus(response.data["status"])
        }, (error) => {
          console.log(error);
        });

    }
    else {
      console.log("clean refresh")
    }

    return (() => {
      sessionStorage.clear()
    })



  }, []);

  // window.onbeforeunload = function () {
  //   sessionStorage.setItem("origin", window.location.href);
  // }
  // window.onload = function () {
  //   if (window.location.href == sessionStorage.getItem("origin")) {
  //     sessionStorage.clear();
  //   }
  // }
  // Hub.listen('auth', (data) => {
  //   console.log("AUTH EVENT:", data.payload.event)
  // })
  useEffect(() => {
    console.log(authStatus)
    setAuthStatus(authStatus);

  }, [authStatus])


  useInterval(() => { activityTracker(signOut) }, INTERVAL_TIME);

  useInterval(() => {
    storeWebSession();
  }, 60000);



  window.addEventListener('beforeunload', (e) => { unload_handler(e, signOut) })
  window.addEventListener("mousemove", () => { sessionStorage.setItem('timer', JSON.stringify(Date.now())) });
  window.addEventListener("keypress", () => { sessionStorage.setItem('timer', JSON.stringify(Date.now())) });



  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="WUS logo"
            src="./wake_up_safe_logo.png"
          />
	  {/*<div className = "w-100 ma2 pa1 bg-mint-important white br3">
              <p>
              <b>Wake up safe will be undergoing maintenance on <u>Wednesday Apr 2nd, 2025 from 8 am - 11:59 pm.</u> <br/>
              The website will be down for that duration</b>
              </p>
           </div>*/}
        </View>
      );
    },
    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <View textAlign="left" padding={tokens.space.large}>
            Please refer to
            <Link
              fontWeight="normal"
              style={{ marginInline: "0.25em" }}
              href={mfa_guide_url}
              size="small"
              target="_blank"
            >
              <b>Wake Up Safe MFA Guide</b>
            </Link>
            before you get started
          </View>
        );
      },
      Footer() {
        const { toSignUp, toForgotPassword } = useAuthenticator();
        // console.log(useAuthenticator())
        const [helpFlag, setHelpFlag] = useState(false);


        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignUp}
              size="small"
              variation="link"
            >
              New User?
            </Button>
            <Button
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Forgot Password?
            </Button>
            <br /><br />
            <Link
              fontWeight="normal"
              href={mailto_list}
              size="small"
            >
              Contact Support
            </Link>
            <br />< br />
            <b>Existing Users</b>: Please make sure you install <br />
            <Link
              fontWeight="normal"
              href={security_app_url}
              size="small"
              target="_blank"
            >
              Microsoft Authenticator
            </Link>
            &nbsp;
            on your mobile device
          </View>

        );

      },
    },
    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <View textAlign="left" padding={tokens.space.large}>
            Please refer to
            <Link
              fontWeight="normal"
              style={{ marginInline: "0.25em" }}
              href={mfa_guide_url}
              size="small"
              target="_blank"
            >
              <b>Wake Up Safe MFA Guide</b>
            </Link>
            before you get started
          </View>
        );
      },
      FormFields() {
        const { validationErrors } = useAuthenticator((context) => [context.validationErrors]);
        const [careSites, setCareSites] = useState([]);
        useEffect(() => {

          axios.post(service_url + '/care_sites', {}, request_config)
            .then((response) => {
              setCareSites(response.data['result'])
            }, (error) => {
              Swal.fire({
                icon: "error",
                title: "Connection Error",
                allowOutsideClick: false,
                html: "Unable to retrieve care sites. Please <a href=" + { mailto_list } + " target='_blank'>email support</a> \
                            and send a screenshot of this error message<br><br>\
                            <div class='black'>Database Connection Error </div>"
              }).then((response) => {
              })
            })
        }, [])
        if (careSites.length == 0) {
          return (<div className="tc">Retreiving Care Sites ....</div>)
        }
        else {

          return (
            <>
              <TextField
                isRequired={true}
                errorMessage={validationErrors.fname}
                hasError={!!validationErrors.fname}
                key="firstname"
                name="firstname"
                placeholder="First Name"
                onChange={(e) => { sessionStorage.setItem('lname', e.target.value) }} />
              <TextField
                isRequired={true}
                errorMessage={validationErrors.lname}
                hasError={!!validationErrors.lname}
                key="lastname"
                name="lastname"
                placeholder="Last Name"
                onChange={(e) => { sessionStorage.setItem('fname', e.target.value) }} />
              <SelectField
                isRequired={true}
                errorMessage={validationErrors.institution}
                hasError={!!validationErrors.institution}
                key='institution'
                name='institution'
                placeholder="Institution"
                onChange={(e) => { sessionStorage.setItem('site_id', e.target.value) }}>
                {careSites.map((item, index) => {
                  return <option key={'inst_' + item['site_id']} value={item['site_id']}>{item['site_name']}</option>
                })}

              </SelectField>
              <SelectField
                isRequired={true}
                errorMessage={validationErrors.role}
                hasError={!!validationErrors.role}
                key='role'
                name='role'
                placeholder="Role"
                onChange={(e) => { sessionStorage.setItem('role', e.target.value) }}>
                <option value="1">Administrator</option>
                <option value="2">IT</option>
                <option value="3">Provider</option>
              </SelectField>

              <Authenticator.SignUp.FormFields />
              <CheckboxField
                errorMessage={validationErrors.acknowledgement}
                hasError={!!validationErrors.acknowledgement}
                name="twofa_consent"
                value="yes"
                label="As part of an effort to increase information security, Wake Up Safe will add two-factor authentication to the case submission portal. By Checking this box when signing up for wake up safe, you consent to receive the verification code from a registered toll free number. "
              />

            </>
          )
        }
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignIn: {
      Footer() {
        const { tokens } = useTheme();

        return (

          <View textAlign="left" padding={tokens.space.large}>
            You need to install
            <Link
              fontWeight="normal"
              style={{ marginInline: "0.25em" }}
              href={security_app_url}
              size="small"
              target="_blank"
            >
              Microsoft Authenticator
            </Link>
            for this code. For more information please refer to the <Link
              fontWeight="normal"
              style={{ marginInline: "0.25em" }}
              href={mfa_guide_url}
              size="small"
              target="_blank"
            >
              <b>Wake Up Safe MFA Guide</b>
            </Link>
          </View>
        );
      },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <View textAlign="left" padding={tokens.space.large}>
            You need to install
            <Link
              fontWeight="normal"
              style={{ marginInline: "0.25em" }}
              href={security_app_url}
              size="small"
              target="_blank"
            >
              Microsoft Authenticator
            </Link>
            for this code. For more information please refer to the <Link
              fontWeight="normal"
              style={{ marginInline: "0.25em" }}
              href={mfa_guide_url}
              size="small"
              target="_blank"
            >
              <b>Wake Up Safe MFA Guide</b>
            </Link>
          </View>
        );
      },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    },

  }

  const handleUpdateMFAPreference = async () => {
    console.log("updated MFA preference called")
    try {
      await updateMFAPreference({ sms: 'ENABLED', totp: 'PREFERRED' });
    } catch (error) {
      console.log(error);
    }
  }



  const signUpAttributes =
    [

      'email',
      // 'family_name',
      // 'given_name',
      'phone_number',
    ]

  const formFields = {
    setupTotp: {
      QR: {
        totpIssuer: 'Wake Up Safe', // Customize the issuer name here
        // totpUsername: 'amplify_qr_test_user', // Customize the user name here
      },
    },
  }

  const services = {
    validateCustomSignUp: (formData) => {
      if (!formData.twofa_consent) {
        return {
          acknowledgement: '',
        };
      }
    },
    handleSignUp: async (input) => {
      // console.log("singup called")
      let username = input["username"]
      let password = input["password"]
      let options = input["options"]
      let email = options['userAttributes']['email']

      options['autoSignIn'] = false

      // try {
      let response = await signUp({
        username, password, options
      });
      sessionStorage.setItem('user_name', username)
      sessionStorage.setItem('user_id', response['userId'])
      sessionStorage.setItem('user_email', email)
      // console.log("sign up done", response)

      return response

    },


    handleConfirmSignUp: async (input) => {
      // console.log("signup confirmation called", input)
      let response = await confirmSignUp(input);
      // console.log("sign up confirmed")
      // console.log("signup confirmation done: ", getUser())
      let user_name = sessionStorage.getItem('user_name')
      let user_id = sessionStorage.getItem('user_id')
      addUser(user_id, user_name)
      // auth_listened = true
      return response

    },

    handleSignIn: async (input) => {
      // console.log(input)
      let response = await signIn(input);
      sessionStorage.setItem('user_name', input['username'])
      // console.log(response)
      return response
    },

    handleConfirmSignIn: async (input) => {
      console.log("signin confirmation called", input)
      let response = await confirmSignIn(input);
      let user = await getCurrentUser()
      getUser(user)
      // console.log("sign in confirmed", response)
      // console.log(mfa_prefs)
      // if(!mfa_prefs['prefered']){
      //   console.log("updating mfa preferences")
      //   handleUpdateMFAPreference()
      // }
      // getUser(response['userId'])

      // const inputOptions = new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve({
      //       "SMS": "Text on ",
      //       "TOTP": "Authenticator App",
      //     });
      //   }, 500);
      // });

      // const { value: mfa_type } = await Swal.fire({
      //   title: "Select Multi factor Authentication type",
      //   input: "radio",
      //   inputOptions,
      //   inputValidator: (value) => {
      //     if (!value) {
      //       return "You need to choose an option";
      //     }
      //   }
      // });
      // Swal.fire({
      //   icon: "success",
      //   title: "User Registered",
      //   allowOutsideClick: false,
      //   html: "User has been successfully registered. <br/> Return to the login page to login into your account."
      // }).then((swal_response) => {
      //   setTimeout(() => {
      //     sessionStorage.clear()
      //     window.location.reload();
      //   }, 500);
      // }, (error) => {
      //   console.log(error)
      //   // return response
      // })
      // auth_listened = true
      return response

    },

  }




  return (<>
    {approvalStatus === '' &&
      <Authenticator
        style={{ width: "50%" }}
        components={components}
        signUpAttributes={signUpAttributes}
        services={services}
        formFields={formFields}
      >

        <article className="vh-50 dt w-100 flex items-center">
          <div className="flex justify-center tc w-100 ph3 ph4-l">
            Processing...
          </div>
        </article>


      </Authenticator>
    }
    {
      approvalStatus !== '' && approvalStatus !== "Approved" &&
      <Authenticator
        style={{ width: "50%" }}
        components={components}
        signUpAttributes={signUpAttributes}
        services={services}
        formFields={formFields}
      >

        <Pending status={approvalStatus} />


      </Authenticator>

    }
    {
      approvalStatus === "Approved" &&
      <Authenticator
        style={{ width: "50%" }}
        components={components}
        signUpAttributes={signUpAttributes}
        services={services}
        formFields={formFields}
      >

        <AppWUS />


      </Authenticator>

    }

  </>)

}

export default App
