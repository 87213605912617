import { useState } from "react";
import { TextField, Autocomplete, Checkbox } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";


export default function MultiSelect({
    items,
    label,
    selectAllLabel,
    onChange
}) {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const allSelected = items.length === selectedOptions.length;

    const handleToggleOption = (selectedOps) => setSelectedOptions(selectedOps);
    const handleClearOptions = () => {
        setSelectedOptions([]);
    }

    const handleSelectAll = (isSelected) => {
        if (isSelected) {
            setSelectedOptions(items);
        } else {
            handleClearOptions();
        }
    };

    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected);
    };

    const handleChange = (event, selectedOps, reason) => {
        if (reason === "selectOption" || reason === "removeOption") {
            if (selectedOps.find((option) => option.title === "Select All")) {
                handleToggleSelectAll();
                const result = items.filter((el) => el.title !== "Select All");
                return onChange(result);
            } else {
                console.log("MUISelect: ", selectedOps)
                handleToggleOption && handleToggleOption(selectedOps);
                return onChange(selectedOps);
            }
        } else if (reason === "clear") {

            handleClearOptions && handleClearOptions();
            return onChange([])
        }
    };

    const filter = createFilterOptions();

    return (
        <Autocomplete
            multiple
            // disableCloseOnSelect
            options={items}
            value={selectedOptions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.title}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return [{ id: 0, title: selectAllLabel }, ...filtered];
            }}
            renderOption={(props, option, { selected }) => {
                // To control the state of 'select-all' checkbox
                // selected = items.map(x=>selectedOptions.includes(x))
                const selectAllProps =
                    option.title === "Select All" ? { checked: allSelected } : {};
                // console.log(allSelected)
                return (
                    <li {...props}>
                        <Checkbox checked={selected} {...selectAllProps} />
                        {option.title}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={label} />
            )}
            onChange={handleChange}
        />
    );
}
