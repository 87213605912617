import React, { useState, useEffect } from 'react';

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Typography } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Container } from "@mui/material";
import CustomDatePicker from "./CustomDatePicker";
import Button from "@mui/material/Button"
import dayjs from 'dayjs';
import MultiSelect from './Multiselect';
import axios from 'axios';
import Swal from 'sweetalert2';
import { v5 as uuidv5 } from 'uuid';
import { request_config, service_url,email_service } from './config';

const variables = [
    { 
        title: "PACU LOS and % with time > 120 min" ,
        id: "pacu_los"
    },
    { 
        title: "Hospital LOS for encounter associated with anesthesiology",
        id: "hospital_los_anesth"
    },
    { 
        title: "Intraoperation cardiac arrest" ,
        id: "intraop_cardiac_arrest"
    },
    { 
        title: "IV opioids in PACU",
        id: "iv_opiods_pacu"
    },
    {
        title: "Pain scores in PACU (% comfortable on arrival to PACU and % rescued within 15 minutes)",
        id: "pains_scores_pacu"
    },
    { 
        title: "Rescue anti-emetics in PACU",
        id: "rescue_anti_emetics_pacu"
    },
    { 
        title: "Highest pain score in PACU",
        id: "highest_pain_score_pacu"
    },
    { 
        title: "Average pain score in PACU",
        id: "avg_pain_score_pacu"
    },
    { 
        title: "7- and 30-day readmissions" ,
        id: "7_and_30_day_readmission"
    },
    { 
        title: "IV morphine equivalents in intraoperation",
        id: "iv_morphine_intraop"
    },
    { 
        title: "IV morphine equivalents in PACU",
        id: "iv_morphine_pacu"
    },
    { 
        title: "RBC transfusion intraoperation" ,
        id: "rbc_transfusion_intraop"
    },
    { 
        title: "Return to ED within 72 hours",
        id: "return_to_ed_72hr"
    },
];

const DenomDataRequest = ()=> {
    const [tor,setTOR] = useState(null)
    const [vars, setVars] = useState([])
    const [fromDate, setFromDate] = useState('01/05/2023')
    const [minDate, setMinDate] = useState('01/05/2023')
    const [toDate, setToDate] = useState(dayjs().format("MM/DD/YYYY"))
    const [comment, setComment] = useState(null)
    const [scope, setScope] = useState(null)

    useEffect(() => {
        setMinDate(fromDate)
    }, [fromDate])
    return (
        <Container sx={{my: 7, }}>

            <Stack spacing={4} sx={{ minWidth: "60%", maxWidth: "60%", ml: 3 }}>
                <FormControl>
                    <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        sx={{ color: "black", fontWeight:500}}
                        align="left"
                    >
                        <b>Type of Request</b>
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e)=>{
                            setTOR(e.target.value)
                        }}
                    >
                        <FormControlLabel
                            value="research"
                            control={<Radio />}
                            label="Research"
                        />
                        <FormControlLabel
                            value="qi"
                            control={<Radio />}
                            label="Quality Improvement"
                        />
                    </RadioGroup>
                </FormControl>
                <div>
                <Typography align="left" sx={{ fontWeight: 500}}>
                    <b>Requested Denominator Variables</b>
                    </Typography>
                {/* <Autocomplete
                    multiple
                    id="tags-standard"
                    options={variables}
                    getOptionLabel={(option) => option.title}
                    value={vars}
                    onChange={(e,value,reason)=>{
                        setVars(value)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Denominator Variables"
                            placeholder="Selected Variables"
                        />
                    )} */}
                    <MultiSelect
                        items={variables}
                        label="Denominator Variables"
                        selectAllLabel="Select All"
                        onChange={(ops_selected) => {
                            setVars(ops_selected)
                        }}
                    />
                </div>
                <div>
                <Typography align="left" sx={{ fontWeight: 500 }}>
                    <b>Select Date Range</b>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid
                            sx={{ mt: 3, mb: 3 }}
                            justifyContent="flex-start"
                            alignItems="center"
                            container spacing={2}>
                            <Grid item xs={0.75}>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                    <strong>From</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <CustomDatePicker id="start-date" value={fromDate} minDate={'01/05/2023'} updateParentDate={setFromDate} />
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                    <strong>To</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <CustomDatePicker id="end-date" value={toDate} minDate={minDate} updateParentDate={setToDate} />

                            </Grid>
                        </Grid>
                </LocalizationProvider>
                </div>

                <br />
                <FormControl sx={{ ml: 3 }}>
                    <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        sx={{ color: "black",fontWeight:500 }}
                        align="left"
                    >
                        <b>Scope of Variables</b>
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e) => {
                            setScope(e.target.value)
                        }}
                    >
                        <FormControlLabel
                            value="care_site"
                            control={<Radio />}
                            label="Care Site"
                        />
                        <FormControlLabel
                            value="wus"
                            control={<Radio />}
                            label="Wake Up Safe Network"
                        />
                    </RadioGroup>
                </FormControl>

                <Typography align="left"><b>Other details</b></Typography>
                <TextField 
                    multiline
                    label="Additional details will help us fulfil your request sooner" 
                    rows={6}
                    onChange={(e) => {
                        setComment(e.target.value)
                    }}
                    />
                <Button
                variant='contained'
                sx={{maxWidth:"20%"}}
                onClick={()=>{
                    let mapped_vars = vars.map(x=>x.id)
                    let var_names = vars.map(x => x.title)
                    if(!tor || !scope || !fromDate || !toDate || !(vars.length)){
                        Swal.fire({
                            icon: "error",
                            title: "All fields are mandatory",
                            allowOutsideClick: false,
                            html: ""
                        }).then((response) => { }, (error) => { })
                    }
                    else{
                        const namespace = '1b0db098-ef29-468a-98be-40d58c91b2c0'
                        let user_id = sessionStorage.getItem("user_id")
                        let user_email = sessionStorage.getItem("user_email")
                        let user_name = sessionStorage.getItem("user_name")
                        let request_id = uuidv5(user_id + '_data_request_' + Date.now(), namespace)
                        let post_data = {
                            "request_id": request_id,
                            "type_of_request":tor,
                            "scope":scope,
                            "start_date":fromDate,
                            "end_date":toDate,
                            "variables": mapped_vars,
                            "variable_names": var_names,
                            "comment":comment,
                            "username":user_name
                        }
                        axios.post(service_url+"/log_data_request",post_data, request_config)
                        .then((response)=>{
                            axios.post(email_service + "/data_request_notify",{...post_data, 
                                "user_name": user_name,
                                "user_email":user_email}, request_config)
                            .then((email_response)=>{
                                Swal.fire({
                                    icon: "success",
                                    title: "Request Submitted",
                                    allowOutsideClick: false,
                                    html: "Request successfully logged. <br/> We will reach out to you with updates. \
                                    Use this request ID: <b>"+request_id+"</b><br/> in any future communications"
                                }).then((response)=>{},(error)=>{})
                            },(email_error)=>{
                                Swal.fire({
                                    icon: "error",
                                    title: "Error notifying admins",
                                    allowOutsideClick: false,
                                    html: "Request successfully logged but unable to notify admins. <br/> Please reach out to us with the screenshot of this error"
                                }).then((response) => { }, (error) => { })
                            })
                        },(error)=>{
                            Swal.fire({
                                icon: "error",
                                title: "Error submitting request",
                                allowOutsideClick: false,
                                html: "Unable to submit the data request. <br/> Please reach out to us with the screenshot of this error"
                            }).then((response) => { }, (error) => { })
                        })
                    }

                }}
                >
                    Submit
                </Button>
            </Stack>
        </Container>
    );
}

export default DenomDataRequest