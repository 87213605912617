import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import "./css/tachyons.min.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { Dayjs } from 'dayjs';
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import dayjs from 'dayjs'

const CustomDatePicker = (props) => {
    const [value, setValue] = useState(props.value === '' || !props.value ? null : dayjs(new Date(props.value)));
    const handleChange = (newValue) => {
        setValue(newValue);
    };
    // const set_value = (newValue) => {
    //     setValue(newValue);
    // };

    const handleClr = (e) => {
        e.stopPropagation();
        setValue(null);
        if (props.handleClear) {
            props.handleClear()
        }
    }

    useEffect(() => {
        if (value === null) {
            props.updateParentDate('')
        }
        else {
            props.updateParentDate(value?.format('MM/DD/YYYY'))
        }
    }, [value])

    useEffect(() => {
        setValue(props.value === '' || !props.value ? null : dayjs(new Date(props.value)))
    }, [props.value])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker

                className='bg-white-50 w-90 br3'
                label={props.label}
                inputFormat="MM/DD/YYYY"
                minDate={props.minDate ? dayjs(props.minDate) : dayjs(new Date("01/01/1985"))}
                maxDate={props.maxDate ? dayjs(props.maxDate) : dayjs()}
                value={value}
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={(e) => handleClr(e)}>
                            <ClearIcon />
                        </IconButton>
                    )
                }}
                InputAdornmentProps={{
                    position: "start"
                }}
                renderInput={(params) => <TextField
                    className='br3'
                    sx={{
                        '.MuiInputBase-input': { padding: "1.5em" },
                    }}
                    id={props.id}
                    {...params} />}
            />
        </LocalizationProvider>

    )

}

export default CustomDatePicker;