import logo from "./img/wake_up_safe_logo.png";
import { AppBar, Box, Typography, Toolbar, Container} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import axios from "axios";
import "./css/tachyons.min.css";
import { Button, useAuthenticator } from '@aws-amplify/ui-react';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { user_manual_url, data_dictionary_url, service_url  } from "./config";
import { createTheme } from "@mui/material";
import { Link } from "@mui/material";
import { ThemeProvider } from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#5ac69f",

    },
    secondary: {
      main: 'rgba(0,0,0,0.54)',
    },
    background: {
      default: '#D9D9D9',
    },
  },
});

var request_config = {
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
}

const NavigationBar = (props) => {
    let navigate = useNavigate();
    const [isVotingMember,setIsVotingMember] = useState(false)
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    let username = '';
    let user_return = sessionStorage.getItem('user_name')
    if(user_return!=null)
    {
        username= user_return;
    }

    useEffect(()=>{

      let dataString = {
        "UserID": sessionStorage.getItem('user_id'),
        "UserName": sessionStorage.getItem('user_name')
      }
      axios.post(service_url + "/get_voting_flag", dataString, request_config)
        .then((response) => {
          setIsVotingMember(response.data['is_voting_member'])
        },(error)=>{

        })
    },[])
    const logout = ()=>{
      let survey_id = sessionStorage.getItem('survey_uuid');
      console.log(survey_id);
      if(survey_id==="null"){
        // console.log("Survey not started")
        signOut();
        // navigate("/")
      }
      else{
        // console.log("Survey started but not completed")
        Swal.fire({
          icon: 'info',
          title: 'Data Loss Warning',
          text: 'You have not submitted this survey, you will lose the data for this survey if you logout',
          showConfirmButton: true,
          confirmButtonText: "Stay on this Page",
          showDenyButton: true,
          denyButtonText: "Logout",
        }).then((result)=>{
          if(result.isDenied){
            
            // location.state = []
            // alert_open = false;
            signOut();
          }
        })
      }
    }

    const cusor_change = ()=>{
      document.body.style.cursor = 'default';
    }

    const goSupport = (e) =>{
      e.preventDefault()
      let survey_id = sessionStorage.getItem('survey_uuid');
      if (survey_id === "null") {

        navigate('/support')
      }
      else {
        // console.log("Survey started but not completed")
        Swal.fire({
          icon: 'info',
          title: 'Data Loss Warning',
          text: 'You have not submitted this survey, you will lose the data for this survey if you continue',
          showConfirmButton: true,
          confirmButtonText: "Stay on this Page",
          showDenyButton: true,
          denyButtonText: "Continue",
        }).then((result) => {
          if (result.isDenied) {
            // let location = useLocation()
            // location.state = []
            navigate('/support')
          }
        })
      }

    }
    const goHome = (e)=>{
      e.preventDefault()
      let survey_id = sessionStorage.getItem('survey_uuid');
      console.log(survey_id);
      if(survey_id==="null"){
        
        navigate('/')
      }
      else{
        // console.log("Survey started but not completed")
        Swal.fire({
          icon: 'info',
          title: 'Data Loss Warning',
          text: 'You have not submitted this survey, you will lose the data for this survey if you continue',
          showConfirmButton: true,
          confirmButtonText: "Stay on this Page",
          showDenyButton: true,
          denyButtonText: "Continue",
        }).then((result)=>{
          if(result.isDenied){
            // alert_open = false;
            // location.state = []
            navigate('/')
          }
        })
      }
    }

    const viewApprovals = (e)=>{
      e.preventDefault()
      navigate('/approval')
    }
  const goDenominator = (e) => {
    e.preventDefault()
    navigate('/dendata')
    }
  const goDataRequest = (e) => {
    e.preventDefault()
    navigate('/dendatarequest')
  }
    return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar sx={{ backgroundColor:"#F8F9FA"}}>
            <Toolbar disableGutters>
              
              
            <Box sx={{ flexGrow: 1, ml:1, fontSize:18, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start' }}>
                <Box
                  component="img"
                  onClick={goHome}
                  onMouseOver={() => { document.body.style.cursor = 'pointer' }}
                  onMouseOut={() => { document.body.style.cursor = 'default' }}
                  className="fl"
                  src={logo}
                  alt="wake up safe logo"
                  sx={{py:2, height:75}}
                />
                <Link sx={{ ml:4,mr: 1, my: 2,textDecoration: "none", color: "#1070fd", }} href="" onClick={(event)=>goHome(event)}>Home</Link>
                <Link sx={{ mx: 2, my: 2,textDecoration: "none", color: "#1070fd" }} href={user_manual_url} target="_blank">User Manual</Link>
                <Link sx={{ mx: 2, my: 2,textDecoration: "none", color: "#1070fd" }} href="" onClick={(event)=>goSupport(event)}>Support</Link>
                <Link sx={{ mx: 2, my: 2,textDecoration: "none", color: "#1070fd" }} href={data_dictionary_url} target="_blank">Data Dictionary</Link>
                <Link sx={{ mx: 2, my: 2, textDecoration: "none", color: "#1070fd" }} href="" onClick={(event) => goDataRequest(event)}>Data<br/>Request</Link>
                {
                isVotingMember &&
                <>
                  <Link sx={{ mx: 2, my: 2,textDecoration: "none", color: "#1070fd", }} href="" onClick={(event) => viewApprovals(event)}>Site User Approvals</Link>
                  <Link sx={{ mx: 2, my: 2,textDecoration: "none", color: "#1070fd", }} href="" onClick={(event) => goDenominator(event)}>Denom. Data Entry</Link>
                </>
                }
            </Box>
            
            <Box sx={{ flexGrow: 0, mr:3 ,display: { xs: 'none', md: 'flex' } }} >

              <Typography
                  sx={{ mx: 3, my: 1, color: 'black', display: 'block' }}
              >
                <span>
                  Signed in as:
                    <strong className="ph2">{username}</strong>
                </span>
                  
              </Typography>
              <Button 
                variant="contained"
                color="white"
                backgroundColor={"#5ac69f"}
                borderColor={"#5ac69f"}
                sx={{ mx: 3, my: 2, color: 'white', display: 'block'}}
                onClick={logout}>
                  Logout
              </Button>
            </Box>
            </Toolbar>
          
        
      </AppBar>
      </ThemeProvider>
    </>
    )
}

export default NavigationBar;